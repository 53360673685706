export default {
  methods: {
    generateFinalNoteAddendum() {
      if (this.encounter.addendums.length > 0) {
        let addendumHeader = `<br><hr><br><center><u>ADDENDUM${this.encounter.addendums.length > 1 ? 'S' : ''}:</u></center><br>`
        addendumHeader += `The following addendum${this.encounter.addendums.length > 1 ? 's have' : ' has'} been made by the originating provider after the note was signed and submitted.<br>`

        let addendumSection = addendumHeader
        this.encounter.addendums
          .sort((a, b) => new Date(b.created) - new Date(a.created))
          .forEach(addendum => {
            addendumSection += `<br><u>Addendum from ${this.formatDate(addendum.created)}</u>`
            addendumSection += `<br>${addendum.notes}<br>`
          })

        return addendumSection
      }

      return ''
    },
  },
}
