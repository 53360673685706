export default {
  data() {
    return {
      telemedicinePhrase: 'a video camera and audio microphone to assess the patient remotely.',
    }
  },
  methods: {
    telemedicineText() {
      return `${this.$custom.randomString(['The', 'This'])} ${
        this.$custom.randomString(['visit', 'encounter'])} ${
        this.$custom.randomString(['today', ''])} was ${
        this.$custom.randomString(['performed', 'conducted'])} ${
        this.$custom.randomString(['with', 'by using', 'by means of'])
      } ${this.telemedicinePhrase}`
    },
    generateFinalNoteFooter() {
      let activeWoundInfectionNote
      let activeWoundInfectionCbc
      let elevatedTemperature
      let inflammationDrainage
      let positiveInfection
      if (this.encounter.encounter_active_wound_infection) {
        activeWoundInfectionNote = this.encounter.encounter_active_wound_infection.note
        activeWoundInfectionCbc = this.encounter.encounter_active_wound_infection.cbc
        elevatedTemperature = this.encounter.encounter_active_wound_infection.elevated_temperature
        inflammationDrainage = this.encounter.encounter_active_wound_infection.inflammation_drainage
        positiveInfection = this.encounter.encounter_active_wound_infection.positive_infection
      }
      const visitLocation = this.encounter.visit_location
      const assessmentPlan = this.encounter.assessment_plan.assessment_plan
      const { addendums } = this.encounter
      const activeWoundInfection = this.$custom.toListReturn([
        elevatedTemperature ? 'Patient has elevated temperature related to a wound. All other causes have been ruled out.' : null,
        positiveInfection ? 'Positive wound biopsy for infection.' : null,
        inflammationDrainage ? 'Severe inflammation or drainage from the wound.' : null], null)
      let finalNoteFooter = ' <br><br>' // Space is added at the start so it doesn't remove the line feeds
      if (this.$custom.notEmpty(this.encounter.labs)) {
        finalNoteFooter += `<hr>LABS:<br>${this.$custom.toListCommaAnd(this.encounter.labs, this.encounter.labs_other)}`
        if (this.encounter.labs_order_date) {
          finalNoteFooter += `<br><br>ORDER DATE:<br>${this.formatDate(this.encounter.labs_order_date)}`
        }
      }
      if (elevatedTemperature || positiveInfection || inflammationDrainage) {
        finalNoteFooter += `<hr>ACTIVE WOUND INFECTION:<br>${activeWoundInfection}`
        finalNoteFooter += `<br><br>NOTES (ACTIVE WOUND INFECTION):<br>${this.$custom.textAreaHtml(activeWoundInfectionNote)}`
        finalNoteFooter += `<br><br>COMPLETE BLOOD COUNT (CBC):<br>${this.$custom.textAreaHtml(activeWoundInfectionCbc)}`
      }
      finalNoteFooter += '<hr><center><u>ASSESSMENT AND PLAN:</u></center><br>'
      if (visitLocation === 'Telemedicine' && !assessmentPlan.includes(this.telemedicinePhrase)) {
        finalNoteFooter += `${this.telemedicineText()}<br><br>`
      }
      finalNoteFooter += `${this.$custom.textAreaHtml(assessmentPlan)} <br>`

      if (addendums.length > 0) {
        let addendumHeader = `<br><hr><br><center><u>ADDENDUM${this.encounter.addendums.length > 1 ? 'S' : ''}:</u></center><br>`
        addendumHeader += `The following addendum${this.encounter.addendums.length > 1 ? 's have' : ' has'} been made by the originating provider after the note was signed and submitted.<br>`

        finalNoteFooter += addendumHeader

        addendums
          .sort((a, b) => new Date(b.created) - new Date(a.created))
          .forEach(addendum => {
            finalNoteFooter += `<br><u>Addendum from ${this.formatDate(addendum.created)}</u>`
            finalNoteFooter += `<br>${addendum.notes}<br>`
          })
      }
      this.final_note.footer = this.$custom.clean(finalNoteFooter, 'Wound Footer', false)
    },
  },
}
