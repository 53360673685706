/* eslint-disable prefer-destructuring */
import SyncEncounters from '@/mixins/SyncEncounters'
import WoundFooter from '@/mixins/final-note/WoundFooter'
import themeConfig from '@themeConfig'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'

export default {
  mixins: [SyncEncounters, WoundFooter],
  data() {
    return {
      noteKey: 0,
      isRedacted: false,
      output: null,
      addendum: null,
      addingAddendum: false,
      appName: themeConfig.app.name,
      showPaymentModal: false,
      syncSuccessful: false,
      paymentCalculated: false,
      deferredCareDebounceTimer: null,
    }
  },
  computed: {
    ...mapGetters(['isOnline']),
  },
  watch: {
    syncSuccessful() {
      // If the sync was initiated to calculate the payment total, show the estimated payment modal
      if (this.syncSuccessful && this.showPaymentModal) {
        this.showPaymentModal = false
        if (!this.encounter.is_signed) this.encounter.is_synced = false
        this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
        this.paymentModal()
        this.paymentCalculated = true
      }
    },
  },
  mounted() {
    // Make sure we're on the correct practice type route
    if (this.practiceType !== this.encounter.practice_type_id) {
      this.$router.push({
        name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).noteRoute,
        query: { id: this.encounter.id },
      })
    }

    // If deferred care, set validate to true
    if (this.encounter.is_deferred_care) {
      this.encounter.is_validated = true
    }
  },
  methods: {
    formatDate(date) {
      return this.$date(date).format('MM/DD/YYYY')
    },
    formatDateTime(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY h:mm:ss A')
    },
    calculatePayment() {
      let disabledMessage = false
      if (this.addingAddendum) {
        disabledMessage = 'Please finish adding the addendum to enable estimated payment calculation.'
      }
      if (this.encounter.is_deferred_care) {
        disabledMessage = 'Estimated payment calculation is not available for deferred care encounters.'
      }
      if (this.encounter.is_signed && !this.encounter.is_synced) {
        disabledMessage = 'Either "Unsign Note" or "Sync Note" to enable calculate estimated payment.'
      }
      if (!this.paymentCalculated && disabledMessage) {
        this.$root.confirm({
          titleIconColor: 'warning',
          title: 'Estimated Payment Calculation Disabled',
          body: disabledMessage,
          cancel: false,
        })
      } else if (this.paymentCalculated || (this.encounter.is_signed && this.encounter.is_synced)) {
        this.paymentModal()
      } else {
        this.showPaymentModal = true
        this.syncNote()
      }
    },
    paymentModal() {
      this.$root.confirm({
        titleIcon: this.icons.mdiCurrencyUsd,
        titleIconColor: 'success',
        title: 'Estimated Payment',
        body: `Estimated payment for this encounter: ${this.$custom.formatCurrency(this.encounter.estimated_payment)}`,
        cancel: false,
      })
    },
    printNote() {
      window.print()
    },
    closeEncounterNote() {
      const practiceType = this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id)
      if ((this.encounter.is_signed && this.encounter.is_synced) || this.encounter.is_deferred_care) {
        this.exit()
      } else {
        this.$store.dispatch('loading', true)
        this.$router.push({
          name: practiceType.encounterRoute,
          query: { id: this.encounter.id },
        })
      }
    },
    deferredCareUpdated() {
      // Debounce
      clearTimeout(this.deferredCareDebounceTimer)
      this.deferredCareDebounceTimer = setTimeout(() => {
        this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
      }, 500)
    },
    deleteDeferredEncounterNote() {
      this.$root.confirm({
        title: 'Delete Deferred Care Encounter Note?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this encounter note?',
        confirm: 'Delete Note',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          if (this.encounter.can_be_deleted) {
            this.$store.commit('encounters/deleteEncounter', this.encounter)
          } else {
            this.encounter.deleted = true
            this.encounter.is_synced = false
            this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
          }
          this.$store.dispatch('notify', { value: 'Encounter note deleted successfully.' })
          this.exit()
        }
      })
    },
    signNote() {
      const hasFacesheet = this.patient.all_attachments ? this.patient.all_attachments?.findIndex(x => x.attachment_type === 'facesheets') !== -1 : false

      this.$root.confirm({
        title: hasFacesheet ? 'Sign Encounter Note?' : 'Facesheet missing!',
        titleIconColor: hasFacesheet ? false : 'error',
        body: hasFacesheet
          ? 'Are you sure you wish to sign this encounter note?'
          : 'Are you sure you wish to sign this encounter note even with a missing facesheet?',
        confirm: 'Sign Note',
        confirmIcon: this.icons.mdiFileSign,
        confirmColor: 'warning',
      }).then(result => {
        if (result) {
          this.encounter.is_quick_entry_validated = true
          this.encounter.is_signed = true
          this.encounter.is_synced = false
          this.encounter.signed_by_user_id = this.$authUser.userId()
          this.encounter.signed_time = this.$custom.utcNow()
          this.encounter.modified = this.$custom.utcNow()
          this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
          this.noteKey += 1
        }
      })
    },
    unsignNote() {
      this.$root.confirm({
        title: 'Unsign Encounter Note?',
        body: 'Are you sure you wish to unsign this encounter note?',
        confirm: 'Unsign Note',
        confirmIcon: this.icons.mdiUndoVariant,
        confirmColor: 'warning',
      }).then(result => {
        if (result) {
          this.encounter.is_signed = false
          this.encounter.is_synced = false
          this.encounter.signed_by_user_id = null
          this.encounter.signed_time = null
          this.encounter.modified = this.$custom.utcNow()
          this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
          this.noteKey += 1
        }
      })
    },
    syncNoteOnly(messageSubject = 'Encounter note') {
      if (this.isOnline) {
        this.$store.dispatch('encounters/syncingProgress', { pos: 0, cnt: 1 })
        this.$store.dispatch('encounters/syncingModal', true)
        this.$store.dispatch('encounters/syncEncounter', this.encounter)
          .then(encounterSuccess => {
            this.$store.dispatch('encounters/syncingModal', false)
            if (encounterSuccess[0].status === 'Success') {
              this.$store.dispatch('notify', { value: `${messageSubject} synced successfully.`, color: 'success' })
            } else {
              this.encounter.is_synced = false // Sync wasn't successful, so set encounter to not synced so it will sync later
              this.errorDialog(encounterSuccess[0].message, `${messageSubject} failed to sync!`)
            }
            this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
          })
      } else {
        // Mark encounter as needed to be synced
        this.encounter.is_synced = false
        this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })

        this.$store.dispatch('notify', { value: 'Addendum successfully added. Connect online to sync.', color: 'warning' })

        // Set sync status to false, user would need to sync the addendum
        this.$store.dispatch('encounters/setSynced', false)
      }
    },
    syncNote() {
      this.syncSuccessful = false
      this.$store.dispatch('encounters/syncingProgress', { pos: 0, cnt: 3 })
      this.$store.dispatch('encounters/syncingModal', true)
      this.$store.dispatch('patients/syncPatient', {
        ...this.patient,
        force_add: true, // Force add the patient, even if a duplicate is detected
      }).then(corePatientRes => {
        if (typeof corePatientRes === 'string') throw corePatientRes
        console.log('Core Patient Sync Response:', corePatientRes)
        this.$store.dispatch('encounters/syncingProgress', { pos: 1, cnt: 3 })
        const corePatientResponseData = corePatientRes.responseData
        const patientData = this.$store.getters['patients/getById'](corePatientResponseData.patientID)

        if (Array.isArray(corePatientResponseData) && corePatientResponseData.length > 0 && corePatientResponseData[0]?.status === 'Success') {
          this.encounter.created_by_user_id = this.$authUser.userId()
          this.encounter.is_synced = true
          this.sanitizeEncounter()
          this.$store.dispatch('encounters/syncEncounter', this.encounter)
            .then(encounterRes => {
              if (typeof encounterRes === 'string') throw encounterRes
              console.log('Encounter Sync Response:', encounterRes)
              this.$store.dispatch('encounters/syncingProgress', { pos: 2, cnt: 3 })
              if (Array.isArray(encounterRes) && encounterRes.length > 0 && encounterRes[0]?.status === 'Success') {
                this.$store.dispatch('patients/syncFullPatient', {
                  ...this.patient,
                }).then(fullPatientRes => {
                  if (typeof fullPatientRes === 'string') throw fullPatientRes
                  console.log('Full Patient Sync Response:', fullPatientRes)
                  if (Array.isArray(fullPatientRes) && fullPatientRes.length > 0 && fullPatientRes[0]?.status === 'Success') {
                    // Any new attachments were synced successfully, remove updated flag
                    this.patient.all_attachments?.forEach(attachment => {
                      delete attachment.updated
                    })

                    // Remove updated flag from wounds and treatments
                    this.patient.wounds?.forEach(wound => {
                      wound.treatments?.forEach(treatment => delete treatment.updated)
                      delete wound.updated
                    })

                    // Mark patient as synced
                    delete this.patient.updated

                    this.$store.commit('patients/updatePatientSync', this.patient)
                    this.$store.dispatch('auth/refresh')
                    this.$store.dispatch('encounters/getEstimatedPayment', this.encounter.id).then(estimatedPayment => {
                      if (estimatedPayment) {
                        this.$store.dispatch('encounters/syncingModal', false)
                        this.encounter.estimated_payment = estimatedPayment
                        if (!this.showPaymentModal) {
                          this.$store.dispatch('notify', { value: 'Encounter note synced successfully.', color: 'success' })
                        }
                        this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
                        this.syncSuccessful = true
                        this.noteKey += 1
                      } else {
                        this.errorDialog(estimatedPayment, 'Encounter failed to sync!')
                      }
                    })
                  } else {
                    let patientErrorsDisplay = 'Full Patient Sync failed'
                    let alternativeMessage = ''
                    console.log(corePatientResponseData)
                    if (corePatientResponseData.message.toLowerCase().includes('1205 lock')) {
                      alternativeMessage = '<strong>Reason:</strong> Server is busy<br>'
                    } else {
                      alternativeMessage = `<strong>Reason:</strong> ${corePatientResponseData.message}<br>}`
                    }

                    patientErrorsDisplay += `
                      <p style="font-size: 11px;">
                      <strong>Name:</strong> ${patientData.first_name} ${patientData.last_name}<br>
                      <strong>DOB:</strong> ${this.$date(patientData.dob).format('MM/DD/YYYY')}<br>
                      ${alternativeMessage}
                      </p>`

                    // fullPatient sync failed...
                    this.errorDialog(patientErrorsDisplay)
                  }
                })
                  .catch(error => {
                    if (error.toLowerCase().includes('network error')) error += '<br><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'
                    this.errorDialog(error)
                  })
              } else {
                let encounterErrorsDisplay = 'Encounter Sync failed'
                let alternativeMessage = ''
                if (encounterRes[0].message.toLowerCase().includes('1205 lock')) {
                  alternativeMessage = '<strong>Reason:</strong> Server is busy<br>'
                } else {
                  alternativeMessage = `<strong>Reason:</strong> ${encounterRes[0].message}<br>`
                }

                encounterErrorsDisplay += `
                <p style="font-size: 11px;">
                <strong>Encounter Id:</strong> ${this.encounter.id}<br>
                <strong>Patient:</strong> ${this.patient.first_name} ${this.patient.last_name}<br>
                <strong>Date Of Service:</strong> ${this.$date(this.encounter.created).format('MM/DD/YYYY HH:mm')}<br>
                ${alternativeMessage}
                </p>`

                // encounter sync failed...
                this.errorDialog(encounterErrorsDisplay)
              }
            })
            .catch(error => {
              if (error.toLowerCase().includes('network error')) error += '<br><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'
              this.errorDialog(error)
            })
        } else {
          let patientErrorsDisplay = 'Core Patient Sync failed'
          let alternativeMessage = ''

          if (corePatientResponseData.message.toLowerCase().includes('1205 lock')) {
            alternativeMessage = '<strong>Reason:</strong> Server is busy<br>'
          } else {
            alternativeMessage = `
            <strong>Reason:</strong> ${corePatientResponseData.message}<br>}
             <p style="color: red;">
             <strong>DO NOT ATTEMPT TO RESET THE LOCAL STORAGE AS IT WILL NOT RESOLVE THIS ISSUE AND WILL ONLY DELETE UNSYNCED RECORDS!</strong>
             </p>`
          }

          patientErrorsDisplay += `
          <p style="font-size: 11px;">
          <strong>Name:</strong> ${patientData.first_name} ${patientData.last_name}<br>
          <strong>DOB:</strong> ${this.$date(patientData.dob).format('MM/DD/YYYY')}<br>
          ${alternativeMessage}
          </p>`

          // corePatient sync failed...
          this.errorDialog(patientErrorsDisplay)
        }
      })
        .catch(error => {
          if (error.toLowerCase().includes('network error')) error += '<br><br>Please check your internet connection and try to sync again. If the problem persists, contact IT Support.'
          this.errorDialog(error)
        })
    },
    errorDialog(response, defaultMessage) {
      this.$store.dispatch('encounters/syncingModal', false)
      this.encounter.is_synced = false
      this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
      this.noteKey += 1
      this.$root.confirm({
        titleIconColor: 'error',
        title: 'Sync Error!',
        body: response === false ? defaultMessage : response,
        cancel: false,
        html: true,
      })
    },
    syncNoteQuery() {
      // check for unsynced encounters for this patient
      const hasUnsyncedNote = this.$store.getters['encounters/unsyncedEncounters'](this.encounter.patient_id, this.encounter.practice_type_id, this.encounter.id, 365)

      // if there is a previous encounter that is unsynced then trigger
      if (hasUnsyncedNote && hasUnsyncedNote.length > 0) {
        this.hasUnsyncedEncounters(hasUnsyncedNote)

        return
      }

      this.$root.confirm({
        title: 'Sync Encounter Note?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to sync this encounter note?',
        confirm: 'Sync Note',
        confirmIcon: this.icons.mdiFileSync,
        confirmColor: 'error',
      }).then(result => {
        if (result) this.syncNote()
      })
    },
    hasUnsyncedEncounters(encounters) {
      // notify & navigate the user to sync that encounter before proceeding
      this.$root.confirm({
        title: 'Notice: unsynced previous encounter',
        subTitle: 'Warning: attempting to sync with an unsynced previous encounter for the same patient will result in errors.',
        body: `Sync ${encounters.length + 1} notes(s) relating to this patient?`,
        confirm: 'Sync Notes',
        confirmColor: 'success',
        large: true,
      }).then(result => {
        if (result) {
          this.patientEncountersConfirmation(encounters)
        }
      })
    },
    patientEncountersConfirmation(encounters) {
      this.$root.confirm({
        title: 'Sync All Encounters for this Patient?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to sync all encounters for this patient?',
        confirm: 'Sync Data',
        confirmIcon: this.icons.mdiSync,
        confirmColor: 'error',
      }).then(result => {
        if (result === true) {
          this.syncPatientEncounters(encounters)
        }
      })
    },
    async syncPatientEncounters(unsyncedEncounters) {
      const patientToSync = []
      patientToSync.push(this.patient)
      unsyncedEncounters.push(this.encounter)

      let message = ''
      let response = ''

      // We only need to sync this note and any previous note for this patient
      try {
        // Reset the sync progress values
        this.syncPos = 0
        this.syncCnt = 5

        // Start the sync process
        this.syncingProgress()
        this.$store.dispatch('encounters/syncingModal', true)
        console.log('---------- Starting Individual Patient Sync ----------')

        // Syncing Core Patient
        message = 'Patient Core'
        const patientCoreRes = await this.syncCorePatients(patientToSync)
        if (patientCoreRes !== true) {
          response += `${patientCoreRes} <hr><br>`
        }
        this.syncingProgress()
        console.log(`Syncing complete for ${message}`)

        console.log('---------- Starting Individual Patient Encounters Sync ----------')

        // Syncing Encounters
        message = 'Encounters'
        const encountersRes = await this.syncEncounters(unsyncedEncounters)
        if (encountersRes !== true) {
          response += `${encountersRes} <hr><br>`
        }
        this.syncingProgress()
        console.log(`Syncing complete for ${message}`)

        console.log('---------- Starting Full Patient Sync ----------')

        // Syncing Full Patient
        message = 'Full Patient'
        const patientFullRes = await this.syncFullPatients(patientToSync)

        if (patientFullRes === true) {
          this.syncingProgress()
          console.log(`Syncing complete for ${message}`)
          console.log('Completed posting patient encounters...')
          this.$store.dispatch('auth/refresh')
          console.log('---------- Sync Completed Successfully ----------')
          this.$store.dispatch('notify', { value: 'Synced Individual Patient Notes.' })
          this.$store.dispatch('encounters/syncingModal', false)
        } else {
          this.$store.dispatch('encounters/setSynced', false)
          response += `${patientFullRes} <hr><br>`
        }

        console.log(response)

        // Throw error if any sync failed
        if (response !== '') throw response
      } catch (Error) {
        response = `Error ocurred with individual patient sync Error : ${Error}`
        this.$store.dispatch('encounters/syncingModal', false)
        this.errorDialog(response)
      }
    },

    getPDF() {
      // download pdf file?
    },
    toggleRedact() {
      this.isRedacted = !this.isRedacted
    },
    addAddendum() {
      this.addendum = null
      this.addingAddendum = true
    },
    cancelAddendum() {
      this.addendum = null
      this.addingAddendum = false
    },

    // TODO: Save addendum should also sync the addendum in real time
    saveAddendum() {
      this.$root.confirm({
        title: 'Save Addendum?',
        subTitle: 'Warning: This operation cannot be undone!',
        html: true,
        body: `Are you sure you wish to save this addendum?<br><br><p>${this.addendum}</p>`,
        confirm: 'Save Addendum',
        confirmIcon: this.icons.mdiContentSaveEdit,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          // Push addendum to encounter addendum array
          this.encounter.addendums.push({
            id: uuidv4(),
            place_of_service_id: this.encounter.place_of_service_id,
            patient_id: this.encounter.patient_id,
            encounter_id: this.encounter.id,
            notes: this.addendum,
            created_by_user_id: this.$authUser.userId(),
            created: this.$custom.utcNow(),
          })

          // Reset and sync note with updated addendums/final note
          this.addendum = null
          this.addingAddendum = false

          if (this.encounter.practice_type_id === 1) {
            this.generateFinalNoteFooter()
            this.encounter.final_note = `${this.final_note.text} ${this.final_note.body} ${this.final_note.footer}`
              .replaceAll('. ', '.&nbsp;')
              .replaceAll('<br>\t', '<br>&nbsp;&nbsp;')
          } else {
            this.generateFinalNote()
            this.encounter.final_note = this.final_note
              .replaceAll('. ', '.&nbsp;')
              .replaceAll('<br>\t', '<br>&nbsp;&nbsp;')
          }

          // Set following values to default value, flagging the encounter note PDF to be redelivered to the facility via
          // 1. manual upload
          // 2. fax
          // 3. PCC
          this.encounter.is_manual_upload = 0
          this.encounter.fax_status_id = 0
          this.encounter.pcc_status_id = 0

          this.encounter.is_revised = 1

          // Values that should be modified when updating an encounter record
          this.encounter.modified = this.$custom.utcNow()

          this.syncNoteOnly('Addendum')
        }
      })
    },

  },
}
